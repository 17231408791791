import React, { useEffect } from 'react';
// import BANNER1 from './assets/banner1.jpg'
import Routes from './Routes';





function App() {

  // const session4Ref = useRef<any>(null);
  // const headerRef = useRef<any>(null);
  // const session1 = useRef<any>(null);

  useEffect(() => {
    // document.addEventListener('scroll',(e) => {
    //     var top = window.pageYOffset
    //     // session4Ref.current.onScroll(top)
    //     // headerRef.current.onScroll(top)
    //     // session1.current.onScroll(top);
    // })
  },[])

  return (
   
      <Routes/>
    
  );
}

export default App;
