import styled from 'styled-components';
import BIKE from '../assets/bike.png';
import GOLF from '../assets/golfcar.png';
import LEAVE from '../assets/leave.png';
import LEAVE2 from '../assets/leave2.png';
import MARINA from '../assets/marina.png';
import PIER from '../assets/pier.png';
import SV1 from '../assets/scrollview1.png';
import SV2 from '../assets/scrollview2.png';
import SV3 from '../assets/scrollview3.png';
import SV4 from '../assets/scrollview4.png';
import SESSION1 from '../assets/session1.png';
import SESSION2 from '../assets/session2.png';
import SESSION3 from '../assets/session3.png';
import SESSION4 from '../assets/session4.png';
import SESSION5 from '../assets/session5.png';
import TITLE1 from '../assets/text1.png';
import TITLE2 from '../assets/text2.png';
import TITLE3 from '../assets/text3.png';
import Cra from '../sessions/Cra';
import CraFour from '../sessions/CraFour';
import CraTwo from '../sessions/CraTwo';
import Features from '../sessions/Features';
import Header from '../sessions/Header';
import ImageParallax from '../sessions/ImageParallax';
import ScrollView from '../sessions/ScrollView';
import Video from '../sessions/Video';


const Container = styled.div`
    display: block;
`;


const Home = () => {
  return  <Container>
  
      <Header/>
      {/* <Form/> */}

      <ImageParallax
          url={SESSION1}
          box={
            `Reinventamos o seu
            destino para
            construir a casa
            dos teus sonhos`
          }
      />

      {/* <BackgroundBox
          picture={SESSION1}
          message={[`Reinventamos o seu`,
          `destino para`,
          `construir a casa`,
          `dos teus sonhos`]}
      /> */}

      <Features
        title={"O RESIDENCIAL"}
        features={[
          {title: "Bar e restaurante Bela Vista",icon:"bar"},
          {title: "Marina Bela Vista",icon:"marina"},
          {title: "Aerodromo Bela Vista",icon:"aerodromo"},
          {title: "Empório Bela Vista",icon:"emporio"},
          {title: "Espaço de beleza Bela Vista",icon:"beleza"},
        ]}
      />

      <ImageParallax
          url={SESSION2}
      />

      <Cra
        svg={TITLE1}
        picture={SESSION3}
        float={BIKE}
        title={"Orla com pista de caminhada"}
        description={"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate villo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. "}
      />

    <ScrollView images={[
        SV1,SV2,SV3,SV4
      ]}/>


      <Video/>

      <CraTwo
        direction='right'
        svg={TITLE2}
        title="Bar e restaurante flutuante"
        description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate villo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. "
        float={PIER}
        float2={LEAVE}
        picture={SESSION5}
      />

      <ScrollView images={[
        SV1,SV2,SV3,SV4
      ]}/>

      <CraTwo
        direction='left'
        svg={TITLE3}
        title="Marina Bela Vista"
        description='Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate villo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. '
        float={MARINA}
        float2={LEAVE2}
        picture={SESSION4}
      />

<ScrollView images={[
        SV1,SV2,SV3,SV4
      ]}/>


<CraFour

        float={GOLF}

      />


  </Container>
}

export default Home;