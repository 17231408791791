import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
    title: string,
    images: string[]
    // features: {
    //     icon?: string,
    //     title: string
    // }[]
}

export const Container = styled.div`
  
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    overflow: hidden;
    width: 100%;
    background-color: #36503c;

    @media(max-width: 1000px){
        padding: 50px 15px;
    }

  .content{
        display: flex;
        align-items: center;

        .view{
            flex: 1;
            height: 
            max-width: 150px;
            margin: 0px 5px;
            animation-fill-mode: both;
            animation-duration: 300ms;
            transition: 200ms;
            opacity: 0;
           
            &.animate {
                opacity: 1;
                animation-name: scaleIn;
            }

            img {
                width: 100%;
                max-width: 150px;
                border-radius: 5%;
                box-shadow: 2px 0px 15px #0003;
            }
        }

  }

  @keyframes scaleIn{
       0% {
        transform: scale(0)
       }
       50% {
        transform: scale(1.2)
       }
       75% {
        transform: scale(0.9)
       }
       100% {
        transform: scale(1)
       }
    }

    @keyframes scaleOut{
       0% {
        transform: scale(1)
       }
       25% {
        transform: scale(1.9)
       }
       50% {
        transform: scale(1.2)
       }
       0% {
        transform: scale(0)
       }
    }
    
`;

const ScrollView = ({title,images = []}:any) => {

    
    const ref = useRef<HTMLDivElement>(null);

    const [animation,setAnimation] = useState<any>({
        background: {
            opacity: 0
        },
        photos: {
            started: false
        },
    })
 

    const refs:{[key: string]:HTMLDivElement|null} = {

    }

   useEffect(() => {
        document.addEventListener('scroll',(e) => {
            let top = ref.current?.getBoundingClientRect().top || 0
            let t1 =  Math.max(0,window.innerHeight - top );
            let t2 =  window.innerHeight - top - window.innerHeight*0.3;
            let max = window.innerHeight/2;

            let newanimation = {
                background: {
                    opacity: Math.min(t1/(window.innerHeight*0.25),1),
                },
                photos: {
                    started: t2 >= 0
                },
               
            }
           
        
            setAnimation((s:any) => {
                if(JSON.stringify(s) !== JSON.stringify(newanimation))
                    return ({...newanimation})
                else 
                    return s
            })
        
        })
   },[document])

   const MemoFunction = useMemo(() => <>
         {images.map((img:any,i:number) => (
        <div className={'view ' +(animation.photos?.started ? "animate" : "")}  style={{animationDelay: (i*100)+"ms"}}>
            <img src={img}/>
        </div>
        ))}
    </>,[animation.photos?.started])

  return <Container ref={ref} style={{...animation.background}}>
   
       <div className='content' >

         { MemoFunction}
   
            {/* <div className='view' >
                <svg viewBox="0 0 75 69" width="100%">
                    <path d="M0 2C0 0.895429 0.895431 0 2 0H73C74.1046 0 75 0.895431 75 2V64.2471C75 65.2503 74.2489 66.0998 73.2539 66.2281C46.2849 69.7072 29.985 69.7067 1.75859 66.2198C0.758584 66.0962 0 65.2445 0 64.2368V2Z" fill="#7a9d82"/>
                </svg>
            </div>
           
            <div className='view'>
                <svg viewBox="0 0 75 69" width="100%">
                    <path d="M0 2C0 0.895429 0.895431 0 2 0H73C74.1046 0 75 0.895431 75 2V64.2471C75 65.2503 74.2489 66.0998 73.2539 66.2281C46.2849 69.7072 29.985 69.7067 1.75859 66.2198C0.758584 66.0962 0 65.2445 0 64.2368V2Z" fill="#7a9d82"/>
                </svg>
            </div>
            
            <div className='view'>
                <svg viewBox="0 0 75 69" width="100%">
                    <path d="M0 2C0 0.895429 0.895431 0 2 0H73C74.1046 0 75 0.895431 75 2V64.2471C75 65.2503 74.2489 66.0998 73.2539 66.2281C46.2849 69.7072 29.985 69.7067 1.75859 66.2198C0.758584 66.0962 0 65.2445 0 64.2368V2Z" fill="#7a9d82"/>
                </svg>
            </div>
            
            <div className='view'>
                <svg viewBox="0 0 75 69" width="100%">
                    <path d="M0 2C0 0.895429 0.895431 0 2 0H73C74.1046 0 75 0.895431 75 2V64.2471C75 65.2503 74.2489 66.0998 73.2539 66.2281C46.2849 69.7072 29.985 69.7067 1.75859 66.2198C0.758584 66.0962 0 65.2445 0 64.2368V2Z" fill="#7a9d82"/>
                </svg>
            </div> */}

       </div>


  </Container>
}

export default ScrollView;