import React from 'react';
import styled from 'styled-components';
import { colors } from '../config';
import BigIcon from '../elements/BigIcon';
import Heading from '../elements/Heading';

interface Props {
    title: string,
    features: {
        icon?: string,
        title: string
    }[]
}

export const Container = styled.div`
  
  display: flex;
  flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;

  .features{
      max-width: 800px;
      text-align: center;

        .feature{
            display: inline-flex;
            width: 150px;
            justify-content:center;
            flex-direction: column;
            align-items: center;
            flex: 1;
            padding: 20px 10px;
        }

  }

  svg{
      z-index: 10;
  }

 
`;

const BackgroundBox = ({title,features}:Props) => {
  return <Container >
   
        {/* <svg style={{position:'absolute',bottom: "100%"}} width="100%" viewBox="0 0 500 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M500 10.7031V0.796643C429.562 7.0299 343.619 10.6946 250.851 10.7031H500ZM250.204 10.7031H0V0.703064C70.6347 6.9932 156.972 10.6945 250.204 10.7031Z" fill="#ffffff"/>
        </svg> */}

        <div style={{height:30}}/>

        <Heading title={title} bold size={2} color={colors.secondary}/>
        <div style={{height: 20}}/>

        <div className='features'>
            {features.map((feature:any) => (<div className='feature'>
                <BigIcon size={60} name={feature.icon} color={colors.secondary}/>
                <div style={{height: 10}}/>
                <Heading title={feature.title} size={6} align="center"/>
            </div>))}
        </div>

        <div style={{height: 20}}/>

        {/* <svg style={{position:'absolute',top: "100%"}} width="100%" viewBox="0 0 500 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L0 0.703092C70.7165 6.55773 157.172 10 250.527 10C343.419 10 429.48 6.59182 500 0.790091L500 0H0Z" fill="#ffffff"/>
        </svg> */}


  </Container>
}

export default BackgroundBox;