import React from 'react';
import styled from 'styled-components';
import Heading from '../components/Heading';

interface Props {
    picture?: string,
    float: string
    float2?: string
    
}

export const Container = styled.div`
  
     display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: center;
   
   
            
    .content {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        max-width: 900px;
        position: relative;

      

        .text{
            flex: 1;
            max-width: 900px;
            height: 100%;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: column;
            background-color: #ffffff;
        }
        .image{
           
          
            flex: 1;
            flex-shrink: 0
            position: relative;
          
            overflow: hidden;
            z-index: 10px;
            margin-top: -50px;

            .float{
                /* position: absolute; */
                bottom: 0;
                right: 0;
                width: 100%;
                min-width: 200px;
               
            }

           
        }

       
    }
    
`;

const CraFour = ({picture,float}:Props) => {
  return <Container >
   
        <div className='content'>
            <div className='text'>
                <div style={{position: 'relative',textAlign:"right",zIndex:10,backgroundColor:"#ffffff",padding:"5px 30px",borderRadius: 50}}>
                    <Heading align='right' title="AGENDAR VISITA"  size={3} bold/>
                </div>
            </div>
            <div className='image'>
                <img src={float} className="float"/>
            </div>
          
        </div>



  </Container>
}

export default CraFour;