import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

import styled from 'styled-components';
import { colors } from '../config';
import { formatDate, formatDateWithExtension, formatTimeWithExtension } from '../functions';
import Heading from './Heading';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    background: ${colors.background};
    position: relative;
    cursor: pointer;

    .background{
        width: 100%;
        height: 300px;
        transition: 200ms;
        overflow: hidden;
        background-color: #000;

        .image{
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            transition: 200ms;

            
        }
    }
    .info {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content:center;
        padding: 24px;
    }

    &:hover{
        .background{
          
            .image {
                transform: scale(1.1);
                opacity: 0.8;
            }
        }
    }

    &.fullscreen {
        .background{
            height: 100%;
        }
        .info {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(0deg,#000a,#0000)
        }
    }

`;

const BlogItem = ({fullScreen,proportion = "3x4",fontSize = 4,background,title,terms = [],date}:any) => {

    const ref = useRef<HTMLDivElement>(null);
    const bg = useRef<HTMLDivElement>(null);

   

    useEffect(() => {
        if(ref.current && bg.current){

            let [w,h] = proportion.split("x")
            let height = ( ref.current.offsetWidth * (h/w) ) 
            ref.current.style.minHeight = height + "px";

            if(!fullScreen)
                bg.current.style.height = height * 0.5 + "px";

        }
    },[ref.current,bg.current])

  return <Container ref={ref} className={fullScreen ? "fullscreen" : ""}>
      <div className='background' ref={bg}>
        <div className='image' style={{backgroundImage: `url(${background})`}}/>
      </div>
      <div className='info'>
          <div style={{paddingBottom: 8}}>
            <Heading size={8} color={fullScreen ? colors.background : colors.gray600} bold title={terms.join(", ")}/>
          </div>
          <Heading size={fontSize} color={fullScreen ? colors.background : colors.gray700} bold title={title}/>
          <div style={{paddingTop: 8}}>
            <Heading size={6} color={fullScreen ? colors.background : colors.gray500} title={formatDateWithExtension(date)}/>
          </div>
      </div>
  </Container>
}

export default BlogItem;