import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *{
        padding: 0;
        margin: 0;
      
        font-family: 'Roboto', sans-serif;
        box-sizing: inherit;
    }

    html,body {
        scroll-behavior: smooth;
        transition: 100ms;
      
    }

    #root{
        scroll-behavior: smooth;
    }
    html {
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        text-size-adjust: 100%;
    }

    a{
        text-decoration: none;
    }


    @keyframes fadeOut {
        0%{
            opacity: 1
        }
        100%{
            opacity: 0
        }
    }
    @keyframes fadeIn {
        0%{
            opacity: 0
        }
        100%{
            opacity: 1
        }
    }
    @keyframes slideFromBottom {
        0%{
            transform: translateY(100px);
            opacity: 0
        }
        80%{
            opacity: 1
        }
        100%{
            transform: translateY(0px)
        }
    }
`;
