import React from 'react';
import { ReactChildren } from 'react';
import styled from 'styled-components';
import { colors } from '../config';

const Container = styled.div`
   
    display: flex;
  
    .flexitem {

    }

`;

type FlexProps = {
  children?: any
  gap?: number
  style?: React.CSSProperties
  type?: "default" | "justify"
  marginHorizontal?: number,
  flex?: number[]
}

const Flex = ({children,flex = [],gap = 0,marginHorizontal = 0,style,type = "default"}:FlexProps) => {
  return <Container style={{margin: `${marginHorizontal}px 0`,...style,}}>
      {React.Children.map(children,(item:any,index:any) => (
        <div className='flexitem' style={{...(type === "justify" ? ({flex: 1}) : ({flex: flex[index]})) ,...(gap > 0 && index > 0) ? ({marginLeft: gap}) : ({})}}>
          {item}
        </div>
      ))}
  </Container>;
}

export default Flex;