import React, { CSSProperties, ReactNode } from 'react';

import styled from 'styled-components';
import { colors } from '../config';

// import { Container } from './styles';

const sizes = {
    1: 56,
    2: 46,
    3: 38,
    4: 26,
    5: 22,
    6: 18,
    7: 17,
    8: 16,
    9: 14,
    10: 12,
}

interface HeadingProps {
    title: string | ReactNode,
    size?: keyof typeof sizes,
    bold?: boolean,
    color?: string | keyof typeof colors,
    align?: "center"|"left"|"right"|"justify",
    style?: CSSProperties,
    decoration?: "line-through"|"underline"|"underline line-through"|"none"
    numberOfLines?: number
}


const Container = styled.div`
  
`;


const Heading = ({title,decoration,size = 5,bold=false,color=colors.gray700,numberOfLines,align,style}:HeadingProps) => {
  
    return <Container style={{lineClamp: numberOfLines,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      textDecorationLine:  decoration,
      fontFamily: "Roboto",
      fontWeight: bold ? "900" : "400",
      fontSize: sizes[size] ? sizes[size] : sizes[5],
      color: color,
      zIndex:1,
      textAlign: align,
      ...style
  }}>
      {title}
  </Container>;
}


export default Heading;