
import styled from 'styled-components';

export const Container = styled.div`
    padding: 50px 100px;
    background-color:#c1d4bf;
    display :flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 600px ){
        padding: 50px;
    }

    @media(max-width: 400px ){
        padding:  10px;
    }

    > .content {

        max-width: 800px;
        width: 100%;

       
        
        > div {
            padding-bottom: 56.25%;
            position: relative;
            height: 0;
        }

        iframe {
            border: none;
            position: absolute;
            border-radius: 10px;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border: 0;
            background-color: #000;
        }
    }
`;

const Video = () => {

  return <Container>
    <div className='content'>
        <div>
            <iframe className="elementor-video-iframe" allowFullScreen={true} title="Player de Vídeo youtube" src="https://www.youtube.com/embed/cddcati94ac?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.residencialbelavistasp.com.br&amp;autoplay=1" data-gtm-yt-inspected-58382034_14="true" id="647121557" data-gtm-yt-inspected-58382034_20="true" data-gtm-yt-inspected-58382034_105="true" data-gtm-yt-inspected-58382034_116="true" data-gtm-yt-inspected-58382034_124="true"></iframe>
        </div> 
    </div>
  </Container>;
  
}

export default Video;