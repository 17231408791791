import React from "react";
import { Switch } from "react-router-dom";

import { Route, BrowserRouter } from "react-router-dom";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Lotes from "./pages/Lotes";
import Post from "./pages/Post";

const Routes = () => {
   return(
       <BrowserRouter>
            <Switch>
                <Route exact path="/"  component={Home}/>
                <Route path="/lotes" component={Lotes}/>
                <Route exact path="/blog/:id" component={Post}/>
                <Route path="/blog" component={Blog}/>
            </Switch>
       </BrowserRouter>
   )
}

export default Routes;