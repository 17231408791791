import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
     font-size: ${(props:any) => props['attr-size']+"px"};
     @media(max-width: 700px){
         &{
            font-size: ${(props:any) => props['attr-size']*0.9}px;
         }
     }
     @media(max-width: 600px){
         &{
            font-size: ${(props:any) => props['attr-size']*0.8}px;
         }
     }
     /* @media(max-width: 700px){
         &{
            font-size: ${(props:any) => props['attr-size']*0.75}px;
         }
     }
  
     @media(max-width: 500px){
         &{
            font-size: ${(props:any) => props['attr-size']*0.50}px;
         }
     } */
`;


interface HeadingProps {
    title: string | ReactNode,
    size?: number,
    bold?: boolean,
    color?: string,
    align?: "center"|"left"|"right"|"justify",
    style?: any,
    decoration?: "line-through"|"underline"|"underline line-through"|"none"
    numberOfLines?: number
}

const Heading = ({title,decoration,size = 20,bold=false,color="#333",numberOfLines,align,style}:HeadingProps) => {
  
    let sizes:any = {
        1: 56,
        2: 46,
        3: 38,
        4: 26,
        5: 22,
        6: 18,
        7: 17,
        8: 16,
        9: 14,
        10: 12,
    }
  
    return <Container className='heading' attr-size={sizes[size] ? sizes[size] : sizes[5]} style={{
      textDecorationLine:  decoration,
      fontFamily: "Roboto",
      fontWeight: bold ? "900" : "400",
     
      color: color.substring(0,1) === "#" ? color : "#333333",
      zIndex:1,
      textAlign: align,
      ...style
  }}>
      {title}
  </Container>;
}


export default Heading;