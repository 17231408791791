import styled from 'styled-components';
import HEADER from '../assets/header.jpeg';
import LOGO from '../assets/logo.svg';
import Heading from '../components/Heading';
import { colors } from '../config';
import ImageParallax from './ImageParallax';


interface Props {
    picture: string
}

export const Container = styled.div`
    height: 100vh;
    background-position: center bottom ;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    display:flex;
    justify-content: center;
    flex-direction: column;
        
    .middle {
        flex: 1;
    }

    .bottom {
        position: relative;
        background: ${colors.background};
    }

    .top {
        display:flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;

        .extension{
            width: 100%;
            height: 0px;
            background-color: ${colors.secondary};
        }
        .logo {
            position: absolute;
            height: 80px;
        }
        svg{
            width: 100%;
        }

        @media(max-width: 900px){
            .extension{
                height: 100px;
            }
            .logo {
                width: 50%;
            }
        }
    }

    .bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
`;

const Header = ({}:any) => {
  return <Container  >

        {/* <svg style={{position:'absolute',bottom: 0}} width="100%" viewBox="0 0 500 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M500 10.7031V0.796643C429.562 7.0299 343.619 10.6946 250.851 10.7031H500ZM250.204 10.7031H0V0.703064C70.6347 6.9932 156.972 10.6945 250.204 10.7031Z" fill="#ffffff"/>
        </svg> */}

            <div className='bg'>
                <ImageParallax url={HEADER}/>
            </div>

        <div className='top'>
            <img className='logo' src={LOGO}/>
            <div className='extension'/>
            <svg width="100%"  viewBox="0 0 172 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M85.5 36C118.276 36 148.374 32.497 172 26.6497V0H0V26.8949C23.4722 32.5947 53.1842 36 85.5 36Z" fill={colors.secondary}/>
            </svg>
        </div>

        <div className='middle'>

        </div>

        <div className='bottom'>
            <svg style={{position:'absolute',bottom: "100%"}} width="100%" viewBox="0 0 500 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M500 10.7031V0.796643C429.562 7.0299 343.619 10.6946 250.851 10.7031H500ZM250.204 10.7031H0V0.703064C70.6347 6.9932 156.972 10.6945 250.204 10.7031Z" fill="#ffffff"/>
            </svg> 
            <div style={{padding: 20,paddingTop:30}}> 
                <Heading align="center" bold title="UM RECANTO DE PAZ, É SEU MERECIMENTO"/>
            </div>
        </div>
        
  </Container>
}

export default Header;