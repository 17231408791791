
export const asyncMap = (array:any[],fn:(value:any,index:number) => void) => {

    let promise = new Promise(function(resolve, reject) {
        
        let results:any[] = [];

        const execute = async (index:number) => {
            let value = array[index];

            try {

            let result = await fn(value,index);
            results.push(result);

            if(index < array.length - 1)
                execute(index+1);
            else
                resolve(results);
            }
            catch {
                reject();
            }
                
        }

        execute(0);

    });

    return promise
}


const weekDays = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado"
]

export const arrayToWeekDay = (days: number[]) => {
    return days.reduce((acc:any,day:any) => {
        let weekDay = weekDays[day];
        if(weekDay)
            return [...acc,weekDay]
    },[])?.join(", ")
}


export const decimalToMoney = (value: any = 0) => {
    return "R$ "+(parseFloat(value).toFixed(2)).toString().replace(".",",")
}

export const decimalToHour = (value: any = 0) => {
    let hour = Math.floor(value);
    let minute = value > 0 ? Math.round((value - hour) * 60) : 0

    return `${hour.toString().padStart(2,"0")}:${minute.toString().padStart(2,"0")}`
}

export const hourToDecimal = (value: string = "00:00") => {
    let [hourTxt,minuteTxt] = value.split(":")
    let hour = parseInt(hourTxt);
    let minute = parseInt(minuteTxt)/60;

    return hour+minute;
}

export const decimalToTime = (value: any = 0) => {
    
    let hour = Math.floor(value);
    let minute = value > 0 ? Math.round((value - hour) * 60) : 0

    return `${hour.toString().padStart(2,"0")}h${minute.toString().padStart(2,"0")}`
}

export const downscaleImage = async (dataUrl:any,scaleX:number = 1,scaleY:number = 1) => {

    var resizedImage;
    var originalSize = (dataUrl.length * (3/4)) - 1
    

    var image = await getImage(dataUrl);

   
    
        // Resize the image
    var canvas = document.createElement('canvas'),
        max_size = 720,
        width = image.width,
        height = image.height;

    if (width > height) {
        if (width > max_size) {
            height *= max_size / width;
            width = max_size;
        }
    } else {
        if (height > max_size) {
            width *= max_size / height;
            height = max_size;
        }
    }
    canvas.width = width;
    canvas.height = height;
    let canvacontext = canvas.getContext('2d')!
    canvacontext.drawImage(image, 0, 0, width, height);
    resizedImage = canvas.toDataURL('image/jpeg');

    const image2 = await getImage(resizedImage);

    var scale = width > height ? height : width;
    var length = 720;

    canvas = document.createElement('canvas');
    canvas.width = length*scaleX;
    canvas.height = length*scaleY;
    canvacontext = canvas.getContext('2d')!
    canvacontext.drawImage(image2,
        (width/2)-(scale/2), (height/2)-(scale/2), 
        scale, scale,
        0,0,
        length*scaleX,length*scaleY);

    let cuttedImage = canvas.toDataURL('image/jpeg');


    var resizedSize = (cuttedImage.length * (3/4)) - 1

    console.log((originalSize/1024/1024) + "/" + resizedSize/1024/1024)

    return cuttedImage

}

export const formatDate = (value: any = 0) => {
    let date = new Date(value);
    let day = date.getDate()
    let month =  date.getMonth()+1
    let year =  date.getFullYear()
    return day.toString().padStart(2,"0")+"/"+month.toString().padStart(2,"0")+"/"+year.toString()
}


function getImage(dataUrl:string): Promise<HTMLImageElement> 
{
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = dataUrl;
        image.onload = () => {
            resolve(image);
        };
        image.onerror = (el:any, err:any) => {
            reject(err.error);
        };
    });
}

export const getAverageRGB  = async (src:any) => {

    const imgEl = await getImage(src);

    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r:0,g:0,b:0},
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch(e) {
        /* security error, img on diff domain */
        return defaultRGB;
    }

    length = data.data.length;

    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i+1];
        rgb.b += data.data[i+2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);

    return rgb;

}

let value = {
	"name": "Gabriel",
    "pets.dog.name": "Thor",
    "pets.cat.name": "Tigrinho",
    "house.address": "Sitio",
    "house.number": 123,
    "others.0": "-",
    "others.1": "-",
    "others.2": "-"
}



let months:any = [
    "Janeiro","Fevereiro","Março","Abril",
    "Maio","Junho","Julho","Agosto",
    "Setembro","Outubro","Novembro","Dezembro"
]
let shortMonths:any = [
    "Jan","Fev","Mar","Abr",
    "Mai","Jun","Jul","Ago",
    "Set","Out","Nov","Dez"
]

let weekdays:any = [
    "Dom","Seg","Ter","Qua","Qui","Sex","Sáb"
]
let weekdaysEnum:any = [
    "DOM","SEG","TER","QUA","QUI","SEX","SAB"
]

let weekdaysextended:any = [
    "Domingo","Segunda","Terça","Quata","Quinta","Sexta","Sábado"
]

let weekdaystring:any = {
    "DOM":"Domingo",
    "SEG":"Segunda",
    "TER":"Terça",
    "QUA":"Quarta",
    "QUI":"Quinta",
    "SEX":"Sexta",
    "SAB":"Sábado"
}

export const decimalToPhone = (phone: any) => {
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/);
    if (match) {
      return ['(', match[1], ') ', match[2], '-', match[3]].join('');
    }
    return null;
   
}

export const decimalToWeekDay = (value: any = 0) => {
    let date = new Date(value);
    let day = date.getDay();

    let daysDiff = Math.floor((Math.abs( date.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) * 100)/100 + 0.01

    
    if(daysDiff < 1)
        return "Hoje"

    else if(daysDiff >= 1 && daysDiff < 2)
        return "Amanhã"

    return weekdays[day];
}

export const formatTime = (value: any = 0) => {
    let date = new Date(value);
    let hours = date.getHours()
    let minutes =  date.getMinutes()
    return hours.toString().padStart(2,"0")+":"+minutes.toString().padStart(2,"0")
}

export const arrayToChunk = (array:any,chunkSize:number) => {
    var R = [];
    for (var i = 0; i < array.length; i += chunkSize)
      R.push(array.slice(i, i + chunkSize));
    return R;
}

export const formatDateWithExtension = (value: any = 0) => {
    let date = new Date(value);
    let dateOnly = new Date(value).setHours(0,0,0,0);

   // let now = new Date();

    let nowDateOnly = new Date().setHours(0,0,0,0);

    let daysDiff = ((nowDateOnly -  dateOnly) / 1000 / 60 / 60 / 24)


    let year = date.getFullYear().toString();
    let month = date.getMonth().toString();
    let day = date.getDate().toString();

    let thisYear = new Date().getFullYear().toString()

    if(daysDiff <= -2){
        return `${day.padStart(2,"0")} de ${months[parseFloat(month)]} ${year !== thisYear ? "de "+year : ""}`
    }
    else if(daysDiff <= -1 && daysDiff > -2){
        return `Amanhã`
    }
    else if(daysDiff <= 0 && daysDiff > -1){
        return `Hoje`
    }
    else if(daysDiff <= 1 && daysDiff > 0)
        return `Ontem`
    else if(daysDiff <= 2 && daysDiff > 1 )
        return `Anteontem`
    else {
        return `${day.padStart(2,"0")} de ${months[parseFloat(month)]} ${year !== thisYear ? "de "+year : ""}`
    }

}

export const formatTimeWithExtension = (value: any = 0) => {
    let date = new Date(value);
    let dateOnly = new Date(value).setHours(0,0,0,0);

   // let now = new Date();

    let nowDateOnly = new Date().setHours(0,0,0,0);

    let daysDiff = ((nowDateOnly -  dateOnly) / 1000 / 60 / 60 / 24)


    let year = date.getFullYear().toString();
    let month = date.getMonth().toString();
    let day = date.getDate().toString();
    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString();


    if(daysDiff < -365)
        return `${day.padStart(2,"0")} de ${months[parseFloat(month)]} às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    else if(daysDiff <= -2 && daysDiff > -365){
        return `${day.padStart(2,"0")} de ${months[parseFloat(month)]} às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    }
    else if(daysDiff <= -1 && daysDiff > -2){
        return `Amanhã às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    }
    else if(daysDiff <= 0 && daysDiff > -1){
        return `Hoje às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    }
    else if(daysDiff <= 1 && daysDiff > 0)
        return `Ontem às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    else if(daysDiff <= 2 && daysDiff > 1 )
        return `Anteontem às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    else if(daysDiff < 365 )
    return `${day.padStart(2,"0")} de ${months[parseFloat(month)]} às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    else {
        return `${day.padStart(2,"0")} de ${months[parseFloat(month)]} de ${year} às ${hour.padStart(2,"0")}:${minute.padStart(2,"0")}`
    }

}

export const formatTwoTimesWithExtension = (value1: any = 0,value2: any=0) => {
    let dateOnly1 = new Date(value1).setHours(0,0,0,0);
    let dateOnly2 = new Date(value2).setHours(0,0,0,0);

    let date1 = new Date(value1);
    let date2 = new Date(value2);

    let now = new Date();
    let nowDateOnly = new Date().setHours(0,0,0,0);;
    let nowyear = now.getFullYear().toString();


    let date1Diff = ((nowDateOnly - dateOnly1) / 1000 / 60 / 60 / 24)

    let date2Diff = ((nowDateOnly - dateOnly2) / 1000 / 60 / 60 / 24)

    let stringDate1 = {
         year: date1.getFullYear().toString(),
         month: date1.getMonth().toString(),
         day: date1.getDate().toString(),
         dayOfWeek: date1.getDay().toString(),
         hour: date1.getHours().toString(),
         minute: date1.getMinutes().toString(),
         date: date1.toDateString(),
         time: date1.toTimeString()
    }

    let stringDate2 = {
        year: date2.getFullYear().toString(),
        month: date2.getMonth().toString(),
        day: date2.getDate().toString(),
        dayOfWeek: date1.getDay().toString(),
        hour: date2.getHours().toString(),
        minute: date2.getMinutes().toString(),
        date: date2.toDateString(),
        time: date2.toTimeString()
   }

  

   const getDatePrefix = (daysDiff:number,date:any) => {

        console.log(daysDiff)

        if(daysDiff <= -2){
            if(stringDate1.year === nowyear && stringDate2.year === nowyear)
                return `${date.day.padStart(2,"0")} de ${months[parseFloat(date.month)-1]}`
            else 
                return `${date.day.padStart(2,"0")} de ${months[parseFloat(date.month)-1]} de ${date.year}`
        }
        else if(daysDiff > -2 && daysDiff <= -1){
            return `Amanhã`
        }
        else if(daysDiff > -1 && daysDiff <= 0){
            return `Hoje`
        }
        else if(daysDiff > 0 && daysDiff <= 1){
            return `Ontem`
        }
        else if(daysDiff <= 2 && daysDiff > 1)
            return `Anteontem`
        else if(daysDiff <= 7 && daysDiff > 2 )
            return `${weekdaysextended[date.dayOfWeek]}`
        else{
            if(stringDate1.year === nowyear && stringDate2.year === nowyear)
                return `${date.day.padStart(2,"0")} de ${months[parseFloat(date.month)-1]}`
            else 
                return `${date.day.padStart(2,"0")} de ${months[parseFloat(date.month)-1]} de ${date.year}`
        }
    } 

    const getTimePrefix = (date:any) => {
        return date.hour.padStart(2,"0")+":"+date.minute.padStart(2,"0")
    } 

    if(stringDate1.date === stringDate2.date && stringDate1.time === stringDate2.time){
        return getDatePrefix(date1Diff,stringDate1)+" às "+getTimePrefix(stringDate1)
    }
    else if(stringDate1.date === stringDate2.date){
        return getDatePrefix(date1Diff,stringDate1)+", entre "+getTimePrefix(stringDate1)+" e "+getTimePrefix(stringDate2)
    }
    else
        return "Entre "+getDatePrefix(date1Diff,stringDate1)+" às "+getTimePrefix(stringDate1)+" e "+getDatePrefix(date2Diff,stringDate2)+" às "+getTimePrefix(stringDate2)
    


}

export const formatCityName = (city: string,state: string) => {
    city = city.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(" ","-");
    return (city+"-"+state).toLowerCase()
}

export const formatAddress = (data: any) => {
    if(!data)
        return ""
    return `${data.streetName || ""}${data.streetNumber ? " , "+data.streetNumber:""}${data.neighborhood ? " - "+data.neighborhood+", " : ""}${data.city} - ${data.state}`
}

export const getDateDifference = (date1: Date,date2: Date) => {

    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return diffTime/1000
}

export const formatDistance = (distance: number,places: number = 2) => {
    if(distance < 1)
        return Math.floor(distance*1000)+" metros"
    if(distance >= 1 && distance < 2)
        return Math.floor(distance*(10*places))/(10*places)+" quilômetro"
    else
        return Math.floor(distance*(10*places))/(10*places)+" quilômetros"
   
}

