import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../config";
import Heading from "../elements/Heading";


export const Container = styled.div`

    z-index: 0;
  
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    

    >.content {
      display: flex;
      justify-content: flex-end;
      align-items :center;
      width: 100%;
      max-width: 1200px;

      > div {
        flex: 1;
        padding: 20px;
      }

      .right {
        flex: 0 0 33%;
        padding: 50px;
        .box {
          background-color: #000a;
          padding: 50px;
          border-radius: 16px;
        }
      }
      
    }
    >.background {
        position: absolute; 
        top: 0px; 
        left: 0px; 
        width: 100%; 
        height: 100vh;
        overflow: hidden;
        z-index: -100;
        clip: rect(0, 100vw, 100vh, 0);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        > .image {
          object-fit: cover;
          object-position: 50% 50%;
          max-width: none;
          display: block;
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100vw;
          height: 100vh;
          overflow: hidden;
          pointer-events: none;
          transform-style: preserve-3d;
          backface-visibility: hidden;
       //   will-change: transform, opacity;
      
        

          z-index: -1;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
      
          filter: none;

        }
    }

    .svg{
      width: 60vw;
    }
`;

interface types {
  url: string
  title?: string
  subtitle?: string
  svg?: string
  box?: string
}

const ImageParallax = ({url,title,subtitle,svg,box}:types) => {

  const ref = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const height = window.innerHeight;
  // const handleResize = () => {
  //   if(!ref.current || !containerRef.current)
  //     return;

  // //  ref.current.style.width = window.innerWidth + "px";
  
  //   let top = containerRef.current?.getBoundingClientRect().top || 0;

  //   ref.current.style.transform = `translate3d(0px, ${-(top)}px, 0px)`;
    
  // }

  const handleScroll = () => {
    if(!ref.current || !containerRef.current)
      return;

  //  ref.current.style.width = window.innerWidth + "px";
  
    let top = containerRef.current?.getBoundingClientRect().top || 0;

    let transformTop = top/3

    if(url && url.includes(".mp4")){

     

        if(top > -window.innerHeight){
          ref.current.play();
        }
        else{
            ref.current.pause();
        }
    }

    ref.current.style.transform = `translate3d(0px, ${transformTop}px, 0px)`;
    
  }


  useEffect(() => {
    handleScroll();
  },[ref.current])

  useEffect(() => {
   // window.addEventListener("resize",handleResize)
    window.addEventListener("scroll",handleScroll);
  },[])

  return (
    <Container ref={containerRef}>

        <svg style={{position:'absolute',top: "0"}} width="100%" viewBox="0 0 500 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L0 0.703092C70.7165 6.55773 157.172 10 250.527 10C343.419 10 429.48 6.59182 500 0.790091L500 0H0Z" fill="#ffffff"/>
        </svg>
    
        <div className="content">

            {!!title && <div>
              {svg && <img className="svg" src={svg}/> }
              <Heading align="center" title={title} size={1} color={colors.background} bold/>
              <Heading align="center"  title={subtitle} size={3} color={colors.background} />
            </div> }

            {!!box && <>
              <div className="right">
                  <div className='box'>
                      {/* <svg viewBox="0 0 75 69" width="100%">
                          <path d="M0 2C0 0.895429 0.895431 0 2 0H73C74.1046 0 75 0.895431 75 2V64.2471C75 65.2503 74.2489 66.0998 73.2539 66.2281C46.2849 69.7072 29.985 69.7067 1.75859 66.2198C0.758584 66.0962 0 65.2445 0 64.2368V2Z" fill="#000000aa"/>
                      </svg> */}
                      <div className='text'><Heading size={4} align="right" title={box} color={"#ffffff"} bold/> </div>
                  </div>
              </div>
            </>}

           
        
        </div>
        <div className="background" >
          {url.includes(".mp4") ? <video ref={ref} autoPlay muted className="image" src={url} loop/> :
           <div ref={ref}
           className="image"
           style={{backgroundImage: `url(${url})`}}
         ></div>
          }
         
        </div>

        <svg style={{position:'absolute',bottom: 0}} width="100%" viewBox="0 0 500 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M500 10.7031V0.796643C429.562 7.0299 343.619 10.6946 250.851 10.7031H500ZM250.204 10.7031H0V0.703064C70.6347 6.9932 156.972 10.6945 250.204 10.7031Z" fill="#ffffff"/>
        </svg>
   
    </Container>
  );
};

export default ImageParallax;
