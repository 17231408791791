import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import BlogItem from '../components/BlogItem';
import { colors } from '../config';
import Flex from '../elements/Flex';
import Heading from '../elements/Heading';
import { arrayToChunk } from '../functions';

const Container = styled.div`
    background: ${colors.gray50};
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;

    .content {
      max-width: 900px;
    }
`;


const Blog = () => {

  const [data,setData] = useState<any[]>([]);

  const getData = async () => {
    let res = await axios.get("http://10.0.0.29:3005/blog");
    setData(res.data);
  }

  const getFirstImageSrc = (html:any) => {
      let element = document.createElement("div");
      element.innerHTML = html;
      let img = element.querySelector("img");
      if(!img)
        return;

      return img.src
  }


  let rows:any[] = [
    data.slice(0,1),
    data.slice(1,3),
    data.slice(3,5),
    ...arrayToChunk(data.slice(5),3).map((a:any) => {
          if(a.length === 1){
            return [...a,null,null]
          }
          if(a.length === 2){
            return [...a,null]
          }
          return a
       }
    )
  ]

  

  useEffect(() => {
    getData();
  },[])

  return <Container>

    <div className='content'>

    <Heading title={"Últimas notícias"} size={3} bold/>

      {
        rows.map((row,index) => {
          
          let proportion:any;

          if(index === 0 )
            proportion = "8x5";
          else if(index === 1)
            proportion = "1x1";
          else if(index === 2)
            proportion = "1x1";
      
          return (
          <Flex type="justify" gap={40} marginHorizontal={40} key={"row "+index}>
              {row && row.map((post:any) => {

                if(!post)
                  return <div/>

                let background = getFirstImageSrc(post.post_content)
              
                return <Link to={"/blog/"+post.post_name}> <BlogItem fullScreen={index === 0} fontSize={index === 0 ? 3 : index > 2 ? 6 : 5} key={post.ID} proportion={proportion} title={post.post_title} date={post.post_date}  terms={post.terms} background={background}/></Link>
                
             })}
          </Flex>
        )})
      }
   
   </div>
     
  </Container>;
}

export default Blog;