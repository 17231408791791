import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Heading from '../elements/Heading';

interface Props {
    float: string
    float2: string
    title: string
    description: string
    picture: string
    direction: "right" | "left" | "center"
    svg: string
}

export const Container = styled.div`
  
     display: flex;
     background-position: center bottom ;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    /* min-height: 700px; */

    .content {

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        /* position: relative; */

        .text{
            flex: 1;
            flex-shrink: 0;
            width: 100%;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items:  center;
            flex-direction: column;
            max-width: 750px;
            padding: 50px 0;
            padding: 50px;

            position: relative;
            .top {
                position: relative;
                .title{
                    /* width: 500px; */
                    z-index: 10;
                    padding: 20px 0;

                    img{
                        width: 100%;
                    }
                }

            }
            .bottom {
                display: flex;
                position: relative;
                width: 100%;
                .limiter{
                    max-width: 400px;
                }   
            }
        }
        .bg{
            width: 100%;
            max-width: 750px;
            background-color: #fff;
            height: 100%;
            position: absolute;
            z-index: 0;
        }
        .image{
            position: relative;
            width: 100%;
            margin-top: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-position: center bottom ;
            background-size: cover;
            background-repeat: no-repeat;

            @media(max-width:770px){
                padding-top: 100px;
                background-image: ${(props:any) => props['attr-bg']};
              //  height: auto;
                .bg{
                    background: transparent;
                }
            }

            .float{
                width: 100%;
                z-index: 1;
           
                margin-top: -5%;
                height: auto;
                display: flex;

                img {
                    width: 100%;
                    max-width: 1600px;
                    margin: none;
                }

            }

        }

       
    }

    .float2{
        position: absolute;
        top: 15%;
        height: 400px;

        @media(max-width:1000px){
            display: none;
        }
    }

   

    @media(max-width:1000px){
    
            /* .content {
                flex-direction: column;
                .text{
                    padding: 50px;
                    margin: 0;
                    justify-content: flex-start;
                    

                    .limiter{
                        width: auto;
                    }
                    margin-bottom: 100px;
                } */
            
                /* .image{
                    flex: 0;
                    margin: 0;
                    padding: 0;
                    .background {
                        max-width: none;
                        height: 200px;
                        width: 100%;
                        border-radius: 0;
                    }
                    .float{
                        left: 10%;
                        height: 300px;
                    }
                } */
            /* } */
        }
        
    /* @keyframes slidefromright {
        0%{
            opacity: 0;
            transform: translateX(100%);
        }
        20%{
            opacity: 1;
        }
      
    } */
    
`;

const CraTwo = ({svg,float,float2,picture,title,description,direction="right"}:Props) => {

    const ref = useRef<HTMLDivElement>(null);

    const [animation,setAnimation] = useState<any>({
        title: {
            opacity: 0
        },
        description: {
            transform: `translateX(0%)`,
            opacity: 0
        },
        background: {
            opacity: 0
        },
        background2: {
            opacity: 0
        },
        float: {
            transform: `translateX(0%)`,
            opacity: 0
        }
    })
 

    const refs:{[key: string]:HTMLDivElement|null} = {

    }

   useEffect(() => {
        document.addEventListener('scroll',(e) => {
            let top = ref.current?.getBoundingClientRect().top || 0
            let t1 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.2);
            let t2 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.4);
            let t3 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.6);
            let t4 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.75);
            let max = window.innerHeight/2;

            let newanimation = {
                title: {
                    opacity: Math.min(t2/(window.innerHeight*0.25),1),
                    transform: `scale(${Math.max(2-t2/(window.innerHeight*0.25),1)})`
                },
                description: {
                    transform: `translateX(${Math.max(100-(t3/max*100),0)}%)`,
                    opacity: Math.min(t3/max,1)
                },
                background: {
                    opacity: Math.min(t1/max,1)
                },
                background2: {
                    opacity: Math.min(t2/max,1)
                },
                float: {
                    transform: `translateY(${Math.max(100-(t4/(window.innerHeight*0.25)*100),0)}%)`,
                    opacity: Math.min(t4/(window.innerHeight*0.25),1)
                }
            }
           
        
            setAnimation((s:any) => {
                if(JSON.stringify(s) !== JSON.stringify(newanimation))
                    return ({...newanimation})
                else 
                    return s
            })
          
        
            // setAnimation({
            //     title: {
            //         opacity: titleOpacity
            //     }
            // })

            // if(anime){
            //     if(refs.title) refs.title.style.animation = "slideFromBottom 1s";
            //     if(refs.description) refs.description.style.animation = "fadeIn 1s";
            // }
            // else {
            //     if(refs.title) refs.title.style.animation = "";
            //     if(refs.description) refs.description.style.animation = "";
            // }

        
        })
   },[document])

  return <Container ref={ref} attr-bg={`url(${picture})`} style={{backgroundImage: `url(${picture})`,...animation.background}}>
   
        <div className='content'>
            <div className='text'>
                <div className='bg'  style={{...animation.background2}}/>
                <div className='top'>
                    <div className='title' style={{...animation.title}}>
                        <img src={svg}/>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='limiter'  style={{...animation.description}} ref={(ref) => refs.description = ref}>
                        <Heading align='left' title={description} size={7}/>
                    </div> 
                </div>
            </div>
            <div className='image'>
                <div className='bg'  style={{...animation.background2}}/>
                <div className="float" style={{
                    ...(direction === "right" && ({     
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                    })),
                    ...(direction === "left" && ({     
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                    })),
                    ...(direction === "center" && ({     
                        alignItems: 'center',
                        justifyContent: 'center'
                    }))

                }} >
                    <img src={float} ref={(ref) => refs.float1 = ref}  style={{...animation.float}}/>
                </div>
            </div>
        </div>
        
        <img src={float2} className="float2" ref={(ref) => refs.float2 = ref} style={{
                    ...(direction === "right" && ({     
                      left: 0
                    })),
                    ...(direction === "left" && ({     
                        right: 0
                    })),
                    ...(direction === "center" && ({     
                        left: 0,
                        right: 0
                    }))
            }}/>

        
  </Container>
}

export default CraTwo;