import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Heading from '../elements/Heading';

interface Props {
    picture: string,
    float: string
    title: string
    description: string
    svg: string

}

export const Container = styled.div`
  
     display: flex;
     overflow: hidden;
    position: relative;
    min-height: 700px;

    .content {

        display: flex;
        width: 100%;
        /* position: relative; */

        .text{
            flex: 1;
            flex-shrink: 0;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: column;
            padding: 50px 0;
            margin: 0 50px;

            .title{
                    /* width: 500px; */
                    z-index: 10;
                    padding: 20px 0;

                    img{
                        width: 100%;
                       max-width: 350px;
                    }
                }

            .limiter{
                width: 300px;
            }
        }
        .image{
            flex: 1;
            flex-shrink: 0;
            padding: 50px 0;
            position: relative;
            width: 100%;
            margin: 0 50px;

            .background {
                max-width: 600px;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: right;
                border-radius: 20px 50% 20px 20px;
                margin: 0;  
            }

            .bg {
                 width: 100%;
                height: 100%;
            }

            .float{
                position: absolute;
                bottom: 0;
                left: -100px;
                height: 500px;

            }
        }

       

       
    }

    @media(max-width:1000px){
        height: auto;
    
            .content {
                flex-direction: column;
                .text{
                    padding: 50px;
                    margin: 0;
                    justify-content: flex-start;

                    .limiter{
                        width: auto;
                    }
                    margin-bottom: 100px;
                }
            
                .image{
                    flex: 0;
                    margin: 0;
                    padding: 0;
                    .background {
                        max-width: none;
                        height: 200px;
                        width: 100%;
                        border-radius: 0;
                    }
                    .float{
                        left: 10%;
                        height: 300px;
                    }
                }
            }
        }
        
    /* @keyframes slidefromright {
        0%{
            opacity: 0;
            transform: translateX(100%);
        }
        20%{
            opacity: 1;
        }
      
    } */
    
`;

const Cra = ({svg,picture,float,title,description}:Props) => {

    const ref = useRef<HTMLDivElement>(null);
    const [animation,setAnimation] = useState<any>({
        title: {
            opacity: 0
        },
        description: {
            transform: `translateX(0%)`,
            opacity: 0
        },
        background: {
            opacity: 0
        },
        // background2: {
        //     opacity: 0
        // },
        float: {
            transform: `translateX(0%)`,
            opacity: 0
        }
    })

    useEffect(() => {
        document.addEventListener('scroll',(e) => {
            let top = ref.current?.getBoundingClientRect().top || 0
            let t1 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.2);
            let t2 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.4);
            let t3 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.6);
            let t4 =  Math.max(0,window.innerHeight - top - window.innerHeight*0.75);
            let max = window.innerHeight/2;

            let newanimation = {
                title: {
                    opacity: Math.min(t2/(window.innerHeight*0.25),1),
                    transform: `scale(${Math.max(2-t2/(window.innerHeight*0.25),1)})`
                },
                description: {
                    transform: `translateX(${Math.max(100-(t3/(window.innerHeight*0.25)*100),0)}%)`,
                    opacity: Math.min(t3/(window.innerHeight*0.25),1)
                },
                background: {
                    opacity: Math.min(t3/max,1)
                },

                float: {
                    transform: `translateX(${Math.max(100-(t2/(window.innerHeight*0.5)*100),0)}%)`,
                    opacity: Math.min(t2/(window.innerHeight*0.5),1)
                }
            }
           
        
            setAnimation((s:any) => {
                if(JSON.stringify(s) !== JSON.stringify(newanimation))
                    return ({...newanimation})
                else 
                    return s
            })
          
        
        })
   },[document])

  return <Container ref={ref} >
   
        <div className='content'>
            <div className='text'>
               
                <div className='title' style={{...animation.title}}>
                    <img src={svg}/>
                </div>
                <div className='limiter' style={{...animation.description}}>
                    <Heading align='justify' title={description} size={7}/>
                </div> 
            </div>
            <div className='image'>
                <div className='background' style={{backgroundImage: `url(${picture})`,...animation.background}}/>
                <img src={float} className="float" style={{...animation.float}}/>
            </div>
        </div>

  </Container>
}

export default Cra;