import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import styled from 'styled-components';
import BlogItem from '../components/BlogItem';
import { colors } from '../config';
import Flex from '../elements/Flex';
import Heading from '../elements/Heading';
import { arrayToChunk, formatDateWithExtension } from '../functions';

const Container = styled.div`
    background: ${colors.background};

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;

    .content {
      max-width: 750px;

      img {
        width: 100%;
        margin: 20px 0;
        border-radius: 15px;
      }

      figcaption {
        padding: 0 25px;
        color: #6d6d6f;
        margin-bottom: 24px;
        font-size: 12px;
        line-height: 1.47059;
        font-weight: 600;
        max-width: 700px;
        letter-spacing: -.022em;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
      }

      p{
        padding: 0 25px;
        color: #3d3d3f;
        margin-bottom: 24px;
        font-size: 18px;
        max-width: 700px;
        line-height: 1.47059;
        font-weight: 400;
        letter-spacing: -.022em;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
      }

    }
    
`;


const Post = ({match}:any) => {

  const { id }:any = match.params
  const [data,setData] = useState<any>();

  const getData = async () => {
    let res = await axios.get("http://10.0.0.29:3005/blog/"+id);
    setData(res.data);
  }

 
  

  useEffect(() => {
    getData();
  },[])

  if(!data)
    return <></>

  return <Container>

    <div className='content' >
    
    <Heading size={6} color={ colors.gray500} title={formatDateWithExtension(data.post_date)}/>
      <Heading title={data.post_title || ""} size={2} bold/>

      <div className='post' dangerouslySetInnerHTML={{
        __html:  data && data.post_content
      }}/>
      
    </div>
     
  </Container>;
}

export default Post;